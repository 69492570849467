import React, { useContext } from "react"
import { Context } from "../../context/SiteProvider"
import * as Style from "./RankingList.module.scss"

const Loader = () => {
  return (
    <div className="lds-ring2">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

const RankingList = () => {
  const { loading, inititativeData } = useContext(Context)

  let content

  if (loading) {
    content = (
      <div>
        <Loader />
      </div>
    )
  } else if (inititativeData && inititativeData?.length !== 0 && !loading) {
    const topFiverArr = inititativeData.map((item, index) => {
      return (
        <div className={Style.singleTopVote} key={index}>
          <div className={Style.headingWrapper}>
            <p className={Style.place}>{`${index + 1}. ${
              Number(item.post_code) !== 6300 ? "с." : "гр."
            } ${item.area_bg} - ${item.votes}`}</p>
          </div>
        </div>
      )
    })

    content = (
      <div>
        <h5 className={Style.firstPlaceHeading}>Активност по населени места</h5>
        <div className={Style.itemWrapper}>
          <h2 className={Style.info}>
            Tази класация не е окончателна, в нея не са отразени невалидните
            гласове. Крайното класиране ще бъде обявено на 10 август.
          </h2>
          {topFiverArr}
        </div>
      </div>
    )
  }

  return (
    <div className={`${Style.topVotesWrapper} main-container`}>{content}</div>
  )
}

export default RankingList
