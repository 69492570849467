import React from "react"
import Layout from "../components/layout/Layout"
import RankingList from "../components/ranking-list/RankingList"

const Ranking = () => {
  return (
    <Layout>
      <RankingList />
    </Layout>
  )
}

export default Ranking
